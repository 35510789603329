import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestSettingProfileService {
    show(id) {
        promise = api.client.get(config.end_point.users + '/' + id);
        return promise;
    }
    
    update(id, data) {
        promise = api.client.put(config.end_point.users + '/' + id, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.users + '/' + id);
        return promise;
    }
}

export const guestSettingProfileService = new GuestSettingProfileService();
