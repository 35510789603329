import {alertService} from "@/services/alert/alert.service";
import {guestSettingProfileService} from "@/services/guest/setting/profile.service";

export default {
    data() {
        return {
            user: {},
            userReady: false,
            form: {
                auth_type: 'guest'
            },
            error: null
        }
    },
    async mounted() {
        const auth = JSON.parse(localStorage.getItem('app_auth'));
        this.get(auth.value.id)
    },
    methods: {

        async get(id) {

            this.userReady = false
            var response = await guestSettingProfileService.show(id);
            this.user = response.data
            this.userReady = true

        },

        async update$() {
            alertService.loading();
            this.error = null
            await guestSettingProfileService.update(this.user.id, this.form)
                .then((response) => {
                    this.get(this.user.id)
                    this.hideModal()
                    alertService.success(this.$t('guest.setting.profile-info'), this.$t('guest.setting.updated-successfuly'));
                }).catch((error) => {
                    alertService.close();
                    this.error = error.message
                    console.log(error)
                });
        },

        async delete$() {
            var callback = async () => {
                await guestSettingProfileService.delete(this.user.id)
                .then((response) => {
                    alertService.success(this.$t('guest.global.success'), this.$t('guest.setting.account-deleted-successfully'));
                    setTimeout(() => {
                        this.$storage.remove("auth");
                        this.$router.push({ name: "guest-login" });
                    }, 3000);
                }).catch((error) => {
                    console.log(error)
                    alertService.error(this.$t('guest.global.error'), this.$t('guest.setting.you-cant-delete-account'));
                });

            }
            alertService.confirmation(callback, this.$t('guest.setting.are-you-sure-account'), this.$t('guest.setting.cant-be-revert-account'));
        },

        showModal() {
            this.error = null
            this.form = {
                name: this.user.name,
                email: this.user.email,
                phone_number: this.user.phone,
                auth_type: 'guest',
                birth_year: this.user.birth_year ? this.user.birth_year : null,
                birth_month: this.user.birth_month ? this.user.birth_month : null,
                birth_day: this.user.birth_day ? this.user.birth_day : null,
            }
            this.$bvModal.show('profile-modal')
        },

        hideModal() {
            this.$bvModal.hide('profile-modal')
        },
    }

}