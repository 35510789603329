import {alertService} from "@/services/alert/alert.service";
import {guestSettingCardService} from "@/services/guest/setting/card.service";
const api_key = 'pk_test_51L0hsAHOE79ENqkGvjxNITNSf4sQEcv9TsdvpZ2WQ3L2T7SFAqMDhroYdKkI2ufDWdpkRVkf3cKuP1hbW3fkNOrH00cMWAjq9y';
export default {
    data() {
        return {
            user: {},
            userReady: false,
            // stripeAPIToken: process.env.VUE_APP_STRIPE_API_KEY,
            stripeAPIToken: api_key,
            stripe: '',
            elements: '',
            card: '',
            stripe_form: {
                number: '',
                cvc: '',
                exp_month: '',
                exp_year: ''
            },
            error: null
        }
    },
    async mounted() {
        this.get()
    },
    methods: {

        async get(id) {
            this.userReady = false
            var response = await guestSettingCardService.get();
            this.user = response.data
            this.userReady = true

        },

        init() {
            this.includeStripe(
                "js.stripe.com/v3/",
                function () {
                    this.configureStripe();
                }.bind(this)
            );
        },

        
        includeStripe( URL, callback ){
            // Includes Stripe.js dynamically

            let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);

        },

        configureStripe(){
            // Configures Stripe by setting up the elements and 
            // creating the card element.

            this.stripe = Stripe(this.stripeAPIToken);

            this.elements = this.stripe.elements({locale: this.$i18n.locale});
            this.card = this.elements.create('card', {
                hidePostalCode: true,
            });
            this.card.mount('#card-element');

            // var cardNumberElement = this.elements.create('cardNumber');
            // cardNumberElement.mount('#card-number-element');

        },

        async generateToken() {
            alertService.loading();
            this.error = null
            const response = await this.stripe.createToken(this.card)
            if(response.token) this.store$(response.token)
            if(response.error) {
                alertService.close();
                this.error = response.error.message
            }

        },

        async store$(x) {
            this.error = null
            await guestSettingCardService.store({card_token: x.id})
                .then((response) => {
                    this.get()
                    this.hideModal()
                    alertService.success(this.$t('guest.setting.credit-card-information'), this.$t('guest.setting.registered-successfuly'));
                }).catch((error) => {
                    alertService.close();
                    this.error = error.message
                    console.log(error)
                });
        },

        async showModal() {
            this.error = null
            await this.init()
            this.$bvModal.show('card-modal')
        },

        hideModal() {
            this.$bvModal.hide('card-modal')
        },
    }

}