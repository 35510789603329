import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestSettingCardService {
    get() {
        promise = api.client.get(config.end_point.guest.card.get);
        return promise;
    }

    store(data) {
        promise = api.client.post(config.end_point.guest.card.store, data);
        return promise;
    }
}

export const guestSettingCardService = new GuestSettingCardService();
