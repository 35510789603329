import {alertService} from "@/services/alert/alert.service";
import {guestSettingCarService} from "@/services/guest/setting/car.service";

export default {
    data() {
        return {
            cars: [],
            carsReady: false,
            car: {},
            error: null,
            valid: false, // 予約入力フォームのバリデーションチェック
        }
    },
    async mounted() {
        this.list$()
    },
    methods: {
        async list$() {

            this.carsReady = false
            await guestSettingCarService.list({
                paginate: 0,
                per_page: 10,
            }).then((response) => {
                this.cars = response.data
                this.carsReady = true
            }).catch((error) => {
                console.log(error)
            });

        },

        async store$() {
            if (!this.$refs.form.validate()) return
            alertService.loading();
            await guestSettingCarService.store(this.car)
                .then((response) => {
                    this.list$()
                    this.hideModal()
                    alertService.success(this.$t('guest.setting.car-information'), this.$t('guest.setting.registered-successfuly'));
                }).catch((error) => {
                    alertService.close();
                    this.error = error.message
                    console.log(error)
                });

        },

        async update$(id) {
            if (!this.$refs.form.validate()) return
            alertService.loading();
            await guestSettingCarService.update(id, this.car)
                .then((response) => {
                    this.list$()
                    this.hideModal()
                    alertService.success(this.$t('guest.setting.car-information'), this.$t('guest.setting.updated-successfuly'));
                }).catch((error) => {
                    alertService.close();
                    this.error = error.message
                    console.log(error)
                });
                
        },

        async delete$(id) {
            var callback = async () => {
                await guestSettingCarService.delete(id)
                .then(() => {
                    this.list$()
                    alertService.success(this.$t('guest.global.success'), this.$t('guest.global.success'));
                })
                .catch((response) => {
                    alertService.error(this.$t('guest.global.error'), response.message);
                });
            }
            alertService.confirmation(callback, this.$t('guest.setting.are-you-sure-car'), this.$t('guest.setting.cant-be-revert-car'));
        },

        showModal(data) {
            this.error = null
            this.car = {
                lang: this.$i18n.locale
            }
            if(data) {
                this.car = {
                    region: data.region,
                    sort_number: data.sort_number,
                    hiragana: data.hiragana,
                    registered_number: data.registered_number,
                    id: data.id,
                    lang: this.$i18n.locale
                }
            }
            this.$bvModal.show('car-modal')
        },

        hideModal() {
            this.car = {}
            this.$bvModal.hide('car-modal')
        },
    }

}