import carComponent from "./car/car.component.vue";
import cardComponent from "./card/card.component.vue";
import profileComponent from "./profile/profile.component.vue";

export default {
    components: {
        carComponent,
        cardComponent,
        profileComponent
    },
    data() {
        return {
        }
    },
    async mounted() {
    },
    methods: {
    }

}