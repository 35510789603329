import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestSettingCarService {
    async list(data) {
        promise = await api.client.get(config.end_point.guest.cars, {params: data});
        return promise;
    }

    store(data) {
        promise =  api.client.post(config.end_point.guest.cars, data);
        return promise;
    }

    update(id, data) {
        promise = api.client.put(config.end_point.guest.cars + '/' + id, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.guest.cars + '/' + id);
        return promise;
    }
}

export const guestSettingCarService = new GuestSettingCarService();
